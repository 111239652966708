import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../toast/CustomToast";

export default function TakeMeeting(){
    const {id} = useParams();
    const [service, setService] = useState(null);
    useTitle("Rendez-vous");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);

    function checkIfUserIsConnect() {
        window.location='/';
    }
    const fetchService = ()=>{
        axiosInstance(accessToken).get('service/getById/'+id)
            .then(function (response) {
                if(response.data==null){
                    setService(null);
                    toastClick("Service non trouvee","error");
                    setTimeout(
                        ()=>{
                            window.location.href='/home/list/'+id;
                        },2500
                    );
                }else {
                    setService(response.data);
                }
            })
            .catch(function (error) {
                toastClick("Service non trouvee","error");
                setTimeout(
                    ()=>{
                        window.location.href='/home/list/'+id;
                    },2500
                );
                setService(null);
            });
    }

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchService();
        },[]
    );

    const disableSubmit = (subscribeBTN)=>{

        try {
            subscribeBTN.disabled = true;
            subscribeBTN.innerHTML = `
                        <span class="spinner-border text-success" role="status" aria-hidden="true"></span>
                Patentez...
        `;
        }catch (e) {

        }

    }

    const subscribeFunction = ()=>{

        let subscribeBTN = document.getElementById("subscribe");
        disableSubmit(subscribeBTN)
        let data = {
            id:null,
            libelle: document.getElementById('libelleInput').value.toString().trim(),
            dateRdv: document.getElementById('dateInput').value,
            heureDebut: document.getElementById('timeInput').value.toString().trim(),
            heureFin:null,
            utilisateur: user,
            centrePartenaire: service.centrePartenaire,
            serviceEntity: service,
            rdv:"PRIS"
        }
        axiosInstance(accessToken).post('rendezvous/save', data)
            .then(function (response) {
                enableSubmit(subscribeBTN)
                toastClick("Rendez-vous Pris","success");
                window.location.href = "/home/meeting";
            })
            .catch(function (error) {
                enableSubmit(subscribeBTN)
                toastClick("Erreur survenue lors de la prise de rendez-vous","error");
            });

    }

    const enableSubmit = (subscribeBTN) => {
        try {
            subscribeBTN.disabled = false;
            subscribeBTN.innerHTML = `Prendre rendez-vous`;
        }catch (e) {

        }
    }

    const dateInput = (event)=>{
        let dateInput = document.getElementById("dateInput");
        let selectedDate = new Date(dateInput.value);
        let day = selectedDate.getDay();

        if (day === 0 || day === 6) {
            dateInput.value = '';
            document.getElementById("subscribe").disabled = true;
        } else {
            if (document.getElementById('libelleInput').value.toString().trim().length > 2) {
                if (document.getElementById('timeInput').value.toString().trim() != '') {
                    document.getElementById("subscribe").disabled = false;
                }
            }
        }
    }

    const timeInput = (event)=>{
        let timeInput  = document.getElementById("timeInput");
        let inputTime = timeInput.value;
        let minTime = "08:30";
        let maxTime = "18:30";
        if ((inputTime < minTime) || (inputTime > maxTime)) {
            timeInput.value = '';
            document.getElementById("subscribe").disabled = true;
        } else {
            if (document.getElementById('libelleInput').value.toString().trim().length > 2){
                if (document.getElementById('dateInput').value.toString().trim() != ''){
                    document.getElementById("subscribe").disabled = false;
                }
            }
        }
    }
    const libelleInput = (event)=>{
        let libelleInput  = document.getElementById("libelleInput");
        if (libelleInput.value.trim().length>3) {
            if (document.getElementById('timeInput').value.toString().trim() > 2) {
                if (document.getElementById('dateInput').value.toString().trim() != '') {
                    document.getElementById("subscribe").disabled = false;
                }
            }
        } else {
            document.getElementById("subscribe").disabled = true;
        }
    }


    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Prise de Rendez-vous</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Services</li>
                    <li className="breadcrumb-item active"><a href={"/home/list/"+id}>{id}</a></li>
                </ol>
            </nav>
        </div>
        <section className="section profile">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body pt-3 align-items-center flex-column">
                            <ul className="nav nav-tabs nav-tabs-bordered">
                                <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#profile-overview">Informations sur l'utilisateur
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Nom</div>
                                        <div className="col-lg-9 col-md-8">{user.nom.toUpperCase() + " " + user.prenom}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Numero de Telephone</div>
                                        <div className="col-lg-9 col-md-8">{user.numero_telephone}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Email</div>
                                        <div className="col-lg-9 col-md-8">{user.email == null ? "none" : user.email}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-3 align-items-center flex-column">
                            <ul className="nav nav-tabs nav-tabs-bordered">
                                <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#profile-overview">Informations sur le Service
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Libelle</div>
                                        <div id="libelle" className="col-lg-9 col-md-8">{service?.libelle}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Description</div>
                                        <div className="col-lg-9 col-md-8">{service?.description}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-3 align-items-center flex-column">
                            <ul className="nav nav-tabs nav-tabs-bordered">
                                <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#profile-overview">Informations sur l'USRAJ
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Libelle</div>
                                        <div id="libelle" className="col-lg-9 col-md-8">{service?.centrePartenaire?.libelle}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Description</div>
                                        <div className="col-lg-9 col-md-8">{service?.centrePartenaire?.description}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Localisation</div>
                                        <div className="col-lg-9 col-md-8">{service?.centrePartenaire?.location}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label">Telephone</div>
                                        <div className="col-lg-9 col-md-8">{service?.centrePartenaire?.telephone}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-3 align-items-center flex-column">
                            <ul className="nav nav-tabs nav-tabs-bordered">
                                <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#profile-overview">Choisir la date du rendez-vous
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                <form className="tab-pane fade show active profile-overview" onSubmit={subscribeFunction} id="profile-overview">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Libelle</div>
                                        <div className="col-lg-9 col-md-8">
                                            <input className="form-control" type="text" onChange={libelleInput} id="libelleInput" required={true}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Date</div>
                                        <div className="col-lg-9 col-md-8">
                                            <input className="form-control" type="date" onChange={dateInput} id="dateInput" required={true}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-4 label ">Heure</div>
                                        <div className="col-lg-9 col-md-8">
                                            <input className="form-control" type="time" onChange={timeInput} id="timeInput" required={true}/>
                                        </div>
                                    </div>
                                    <h5>
                                        <input id="subscribe" disabled type="submit" className="btn btn-primary" value={"Prendre rendez-vous"}/>
                                    </h5>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;

}