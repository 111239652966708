import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {logOutUser} from "../configurations/features/authSliceUser";

export function useTitle(title) {
    useEffect(() => {
        const AppName = " | YouthFP"
        const data = title+AppName;
        return () => {
            document.title = data
        }
    })
}
export function useFirstConnexion() {
    const {user, accessToken} = useSelector(state => state.authUser);
    const nav = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        if (accessToken == null) {
            dispatch(logOutUser(""));
            nav('/login');
            window.location.reload();
        }
        if (user == null) {
            dispatch(logOutUser(""));
            nav('/login');
            window.location.reload();
        }
        if (user.isFirstConnexion == false) {
            nav('/reset');
            window.location.reload();
        }
    });

}
export function useMenuAdmin() {
    const {user, accessToken} = useSelector(state => state.authUser);
    const nav = useNavigate();
    const dispatch = useDispatch();
    let view = false;
    useEffect(() => {
        if (accessToken == null) {
            view = false;
        }
        if (user == null) {
            view = false;
        }
        if(user != null){
            if (user.isFirstConnexion == false) {
                view = false;
            }
        }
    });
    return view;

}

function isConnect(token=''){
    if(token===null){
        return false;
    }else if(token.trim()==''){
        return false;
    }else {
        return true;
    }
}

export function isUserConnect() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {accessToken} = useSelector(state => state.authUser);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const nav = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (accessToken == null) {
            nav("/login");
        }
    });

}

export function useRole(roles) {
    const {user, accessToken, droits} = useSelector(state => state.authUser);
    const nav = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        if (isConnect(accessToken) ) {
            let haveRole = false;
            for (let i in roles) {
                if (droits.includes(roles[i])) {
                    haveRole = true;
                }
            }
            if (!haveRole) {
                window.location.href='/';
            }
        }else {
            window.location.href='/';
        }
    },[]);

}