import React, {useEffect, useState} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";

export default function CentrePartenaires(){
    const [cp, setCp]=useState([]);
    useTitle("Abonnement");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }

    const fetchCentrePartenaire = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        axiosInstance(accessToken).get('api/centrepartenaire')
            .then(function (response) {
                let fetchedData = [];
                if(response.data.length <=0){
                    fetchedData.push(
                        <tr>
                            <td colSpan="5">
                                <h3>Aucune USRAJ trouvee</h3>
                            </td>
                        </tr>
                    );
                }else {
                    for (let index = 0; index < response.data.length; index++) {
                        let date = new Date(response.data[index].dateRdv);
                        let day = String(date.getDate()).padStart(2, '0');
                        let month = String(date.getMonth() + 1).padStart(2, '0');
                        let year = date.getFullYear();
                        let formattedDate = `${day}-${month}-${year}`;
                        fetchedData.push(
                            <tr>
                                <th scope="row"><a><img src={response.data[index].photo} alt="" /></a></th>
                                <td><a className="text-primary fw-bold">{response.data[index].libelle}</a></td>
                                <td className="fw-bold">{response.data[index].location}</td>
                                <td className="fw-bold">{response.data[index].telephone}</td>
                                <td>
                                    <a className="text-primary" href={"list/"+response.data[index].id}>
                                        {
                                            response.data[index].serviceOfferts.length != 0 && <button type="button" className="btn btn-info"><i className="bi bi-journal-text"></i></button>
                                        }
                                    </a>
                                </td>
                            </tr>);
                    }
                }
                setCp(fetchedData);
                preloader.classList.add("preloader-deactivate");
            })
            .catch(function (error) {
                let fetchedData = [];
                fetchedData.push(<tr>
                    <td colspan="6">
                        <h3>Impossible d'obtenir les informations</h3>
                    </td>
                </tr>);
                setCp(fetchedData);
                preloader.classList.add("preloader-deactivate");
            });
    }

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchCentrePartenaire();
        },[]
    );

    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>USRAJ (Unitée de Santée de Reproduction Ado Jeune)</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Les USRAJ</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="card-body pb-0">
                                    <h5 className="card-title">USRAJ <span></span></h5>
                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Localisation</th>
                                            <th scope="col">Telephone</th>
                                            <th scope="col">Services</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {cp}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;

}