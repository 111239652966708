import React from "react";
import {toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const data = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false
};

export function CustomToast() {
    return (
            <ToastContainer
                position={data.position}
                autoClose={data.autoClose}
                hideProgressBar={data.hideProgressBar}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false} // mettre à false si vous ne voulez pas que le toast reste visible en survol
                theme="light"
            >{}</ToastContainer>
    );
}

export function toastClick(message, type = "info"|"success"|"warn"|"error") {
    switch (type) {
        case "success":
            toast.success(message, {
                position: data.position,
                autoClose: data.autoClose,
                hideProgressBar: data.hideProgressBar,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        case "info":
            toast.info(message, {
                position: data.position,
                autoClose: data.autoClose,
                hideProgressBar: data.hideProgressBar,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        case "warn":
            toast.warn(message, {
                position: data.position,
                autoClose: data.autoClose,
                hideProgressBar: data.hideProgressBar,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        case "error":
            toast.error(message, {
                position: data.position,
                autoClose: data.autoClose,
                hideProgressBar: data.hideProgressBar,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        default:
            toast(message, {
                position: data.position,
                autoClose: data.autoClose,
                hideProgressBar: data.hideProgressBar,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
    }
}
