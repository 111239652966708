import React, {useEffect, useState} from "react";
import {isUserConnect, useRole, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {Button, notification, Space, Tag, Tooltip} from "antd";
import TableTemplate from "../table";
import {useNavigate} from "react-router-dom";
import {CheckOutlined, CloseCircleOutlined, InfoCircleFilled, MessageOutlined} from "@ant-design/icons";
import {toastClick} from "../toast/CustomToast";

export default function AllMyCentrePartenaires(){
    const [cp, setCp]=useState([]);
    const [rdvs, setRdvs]=useState([]);
    const [loading, setLoading] = useState(true);
    const [usraj, setUsraj]=useState(null);
    const [current, setCurrent] = useState(null);
    const nav = useNavigate();
    useTitle("My CP");
    isUserConnect();
    useRole(['GESTIONNAIRECENTRE']);
    const navigate= (link)=>{
        nav(link);
    }
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }
    const getAllRDV = (usraj)=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        axiosInstance(accessToken).get('rendezvous/getByCpId/'+usraj.id)
            .then(function (response) {
                setRdvs(response.data);
                setUsraj(usraj);
                preloader.classList.add("preloader-deactivate");
            })
            .catch(function (error) {
                setRdvs([]);
                setUsraj(usraj);
                preloader.classList.add("preloader-deactivate");
            });

    }

    const fetchUsraJInfos = (usraj)=>{
        if(usraj ==null) {
            setCurrent(null);
        }else {
            setCurrent(usraj.id)
        }
        getAllRDV(usraj);
    }

    const fetchCentrePartenaire = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        setUsraj(null);
        setLoading(true);
        setRdvs([]);
        axiosInstance(accessToken).get('api/centrepartenaire/getByIdUser/'+user.id)
            .then(function (response) {
                setCp(response.data);
                preloader.classList.add("preloader-deactivate");
                setLoading(false);
            })
            .catch(function (error) {
                setCp([]);
                preloader.classList.add("preloader-deactivate");
                setLoading(false);
            });
    }

    const formatDate = (dateString)=>{
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }
    const sendSMS = (record)=>{
        let sms = record.utilisateur.nom+" n'oubliez pas votre rendez-vous. Date:"+formatDate(record.dateRdv)+" a "+record.heureDebut+" Lieu:"+record.centrePartenaire.nom;
        axiosInstance(accessToken).post(
            "api/message/sendsms",{
                phone:record.utilisateur.numero_telephone,
                message:sms
            }
        ).then((result)=> {
            notification.info({
                style: {
                    zIndex: 0
                },
                message: 'SMS',
                description:
                    'SMS encours d\'envois',

            })
            setLoading(false);
        }).catch((result)=>{
            notification.error({
                style: {
                    zIndex: 0
                },
                message: 'SMS',
                description:
                    'Echec',

            })
            setLoading(false);
        });
    }

    const actionRdv = (record,action)=>{
        let data = record;
        data.rdv=action;
        axiosInstance(accessToken).put('rendezvous/update/admin/'+record.id, data)
            .then(function (response) {
                toastClick("Rendez-vous "+action+" avec success","success");
                window.location.reload();
            })
            .catch(function (error) {
                toastClick("Erreur survenue lors de la mise a jour du rendez-vous","error");
            });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Libelle',
            key: 'libelle',
            render:  (_, record) => (
                <>
          <span>
            {record.libelle}
          </span>
                </>
            ),
        },
        {
            title: 'Patient',
            key: 'utilisateur',
            render:  (_, record) => (
                <>
          <span>
            {record.utilisateur.nom} {record.utilisateur.prenom}
          </span>
                </>
            ),
        },
        {
            title: 'Service',
            key: 'service',
            render:  (_, record) => (
                <>
          <span>
            {record.serviceEntity.libelle}
          </span>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.rdv === 'HONNERE'){
                    result = (
                        <Tag color="green" key={record.id} >
                            {record.rdv}
                        </Tag>
                    )
                }else if(record.rdv === 'ANNULLER'){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            {record.rdv}
                        </Tag>
                    )
                }else if(record.rdv === 'PRIS'){
                    result = (
                        <Tag color="blue"  key={record.id}>
                            {record.rdv}
                        </Tag>
                    )
                }else if(record.rdv === 'ENCOURS'){
                    result = (
                        <Tag color="yellow"  key={record.id}>
                            {record.rdv}
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            {
                                (record.rdv=="PRIS") && <>
                                    <Tooltip title={"Relancer l'utilisateur"}>
                                        <Button type="primary" shape="circle"  onClick={() => sendSMS(record)} >
                                            <i aria-hidden="true" className=""><MessageOutlined /></i>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={"Annuler"}>
                                        <Button type="primary" shape="circle"  onClick={() => actionRdv(record,"ANNULLER")} >
                                            <i aria-hidden="true"><CloseCircleOutlined /></i>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title={"Honnere"}>
                                        <Button type="primary" shape="circle"  onClick={() => actionRdv(record,"HONNERE")} >
                                            <i aria-hidden="true"><CheckOutlined /></i>
                                        </Button>
                                    </Tooltip>
                                </>
                            }
                        </Space>
                    </>
                )
            }
        },
    ];

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchCentrePartenaire();
        },[]
    );

    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Mes USRAJ (Unitée de Santée de Reproduction Ado Jeune)</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Mes USRAJ</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="card-body pb-0">
                                    <h5 className="card-title">USRAJ <span></span></h5>
                                    {
                                        (cp.length > 0) && <>
                                            <hr />
                                            <>
                                            {
                                                cp.map((localUsraj)=>{
                                                    return (
                                                        <Tag title={localUsraj.nom} style={{cursor: 'pointer'}}  color={current === localUsraj.id ? "green" : "blue"}  key={localUsraj.id} onClick={()=>fetchUsraJInfos(localUsraj)}>
                                                            {localUsraj.nom}
                                                        </Tag>
                                                    )
                                                })
                                            }
                                            </>

                                        </>
                                    }
                                    {
                                        (cp.length==0) && <h3>Vide</h3>
                                    }
                                    {
                                        (usraj!=null) && <>
                                            <hr />
                                            <TableTemplate columns={columns} loading={loading} addBTN={null} datas={rdvs} />
                                        </>
                                    }



{/*                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Localisation</th>
                                            <th scope="col">Telephone</th>
                                            <th scope="col">Services</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {cp}
                                        </tbody>
                                    </table>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;

}