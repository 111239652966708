import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: 5,
        backgroundColor: '#ffffff',
    },
    invoice: {
        padding: 10,
        border: '1px solid #dcdcdc',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        zIndex: 1,
    },
    invoiceInformation: {
        textAlign: 'right',
        color: 'gray',
        fontSize: 12,
        marginBottom: 10,
    },
    boldText: {
        color: '#0F172A',
        fontWeight: 'bold',
    },
    invoiceLogo: {
        maxWidth: 100,
        marginBottom: 20,
    },
    clientInfos: {
        fontSize: 12,
        width: '50%',
    },
    clientInfo: {
        fontSize: 12,
        width: '100%',
    },
    invoiceHead: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    table: {
        display: 'table',
        width: 'auto',
        marginTop: 20,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        width: '50%',
        borderStyle: 'solid',
        borderColor: '#dcdcdc',
        borderWidth: 1,
        padding: 8,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    headerText: {
        fontSize: 16,
            textAlign: 'center',
    },
    tableCellHeader: {
        fontWeight: 'bold',
        backgroundColor: '#eeeeee',
    },
    tableCell: {
        fontSize: 12,
        color: 'gray',
    },
    subtotalTable: {
        marginTop: 20,
        alignSelf: 'flex-end',
    },
    totalAmount: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'right',
        color: '#0F172A',
        marginTop: 10,
    },
    footer: {
        fontSize: 10,
        color: 'gray',
        textAlign: 'center',
        width: '100%',
        marginTop: 20,
    },
});

const MyDocument = ({ rendezVousDTO, user }) => {
    const dateFormat = (value)=>{
        let date = new Date(value);
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();
        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    };
    const rdvStatus = (status)=>{
        let className = 'badge ';
        if(status==='PRIS') {
            className += 'bg-warning';
        } else if(status==='HONNERE') {
            className += 'bg-success';
        } else if(status==='ANNULLER') {
            className += 'bg-danger';
        } else if(status==='ENCOURS') {
            className += 'bg-info'
        }
        return className;
    };

    const ufpLogo = '/assets/img/logo.png';
    const ministereLogo = '/assets/img/minsante.jpeg';
    const leftLogo = '/assets/img/logounfpa.png';
    const afriyan = '/assets/img/logoafriyan.png';
    const footerRightImage = '/assets/img/Health-Insurance.jpg';
    return (
        <Document>
            <Page size={[595, 350]} style={styles.page}>
                <View style={{
                    position: 'absolute',
                    opacity: 0.1,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    margin: 'auto',
                }}>
                    <Image src={ministereLogo} />
                </View>

                <View style={styles.invoice}>
                    <View style={styles.header}>
                        <Image src={leftLogo} style={{ width: 50, height: 50 }} />
                        <Image src={ministereLogo} style={{ width: 50, height: 50 }} />
                        <Image src={ufpLogo} style={{ width: 50, height: 50 }} />
                        <Image src={afriyan} style={{ width: 50, height: 50 }} />
                    </View>
                    <View style={styles.header}>
                        <Text style={styles.headerText}>PROJET: <Text>{' PETVISIDAME'}</Text></Text>
                        <Text style={styles.headerText}>Ticket d'Orientation N°: <Text>{rendezVousDTO.centrePartenaire.libelle+' '+rendezVousDTO.id}</Text></Text>
                    </View>
                    <View style={styles.invoiceHead}>
                        <View style={styles.clientInfo}>
                            <Text>Nom de la Personne Orientée: <Text>{user.nom+' '+user.prenom}</Text></Text>
                        </View>
                    </View>
                    <View style={styles.invoiceHead}>
                        <View style={styles.clientInfo}>
                            <Text>Tranche d'age: {'(10-19) (20-25) (26 et plus)'}</Text>
                            <Text>Sexe: {'F(Femme) H(Homme)'}</Text>
                        </View>
                    </View>
                    <View style={styles.invoiceHead}>
                        <View style={styles.header}>
                            <Text style={styles.headerText}>Partie Reserver au Prestataire</Text>
                        </View>
                    </View>

                    <View style={styles.invoiceHead}>
                        <View style={styles.clientInfo}>
                            <Text>USRAJ: {rendezVousDTO.centrePartenaire.nom}</Text>
                            <Text>Service Offert: {rendezVousDTO.serviceEntity.libelle}</Text>
                            <View style={styles.header}>
                                <Text>Date: {rendezVousDTO.dateRdv}</Text>
                                <Text>Heure de Rendez-vous: {rendezVousDTO.heureDebut}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const PDFGenerator = ({ rendezVousDTO, user }) => {
    return (
        <div>
            <PDFDownloadLink document={<MyDocument rendezVousDTO={rendezVousDTO} user={user} />} fileName={rendezVousDTO.id+".pdf"}>
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button className="btn btn-primary">Telecharger PDF</button>)}
            </PDFDownloadLink>
        </div>
    );
};

export default PDFGenerator;
