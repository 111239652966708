import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {toastClick} from "../toast/CustomToast";
import {loginUserClient, logOutUser} from "../../configurations/features/authSliceUser";
import {useTitle} from "../../utils/Utils";

export default function Login(){
    useTitle("Login");
    const dispatch = useDispatch();
    const [login, setLogin] = useState(
        {
            login: '',
            password: ''
        }
    );
    const [active, setActive] = useState(
        {
            login: false,
            password: false
        }
    );


    const checkPassWordValue = (event) => {
        let pwd = event.target.value.toString().trim();
        if (pwd.length < 3) {
            document.getElementById('pwd').style.border= '1px solid #f20c0c';
            active.password=false;
        } else {
            login.password = pwd;
            setLogin(login);
            document.getElementById('pwd').style.border= '1px solid green';
            active.password=true;
        }
        setActive(active)
    }

    const checkLoginValue = (event) => {
        let loginName = event.target.value.toString().trim();
        if (loginName.length < 3) {
            document.getElementById('login').style.border= '1px solid #f20c0c';
            active.login=false;
        } else {
            login.login = loginName;
            setLogin(login);
            document.getElementById('login').style.border= '1px solid green';
            active.login=true;
        }
        setActive(active)
    }

    const submitRegisterForm = (event) => {
        event.preventDefault();

        if (Object.values(active).every(value => value)) {
            document.getElementById('preloader').classList.remove("preloader-deactivate");
            dispatch(loginUserClient(login.login.trim(), login.password.trim()));
        } else {
            toastClick("Champs vide","info");
        }
    };

    useEffect(
        ()=>{
            dispatch(logOutUser());
        },[]
    );

    return <>
        <main>
            <div className="container">
                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a className="logo d-flex align-items-center w-auto">
                                        <img src="/assets/img/logo.png" alt="" />
                                    </a>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Se Connecte a mon compte</h5>
                                            <span id="messageError" className="text-center"
                                                  style={
                                                {
                                                  color: 'red',
                                                  textAlign: 'center',
                                                  display: 'none'
                                                }
                                                }>Login / Mot de passe incorrect</span>
                                        </div>

                                        <form className="row g-3 needs-validation" onSubmit={submitRegisterForm}>

                                            <div className="col-12">
                                                <label htmlFor="yourUsername" className="form-label">Nom d'utilisateur</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                    <input type="text" onKeyUp={checkLoginValue}  name="username" className="form-control" id="login" required={true}  />
                                                        <div className="invalid-feedback">Entre votre nom d'utilisateur</div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <label htmlFor="yourPassword" className="form-label">Mot de passe</label>
                                                <input type="password" onKeyUp={checkPassWordValue} name="password" className="form-control" id="pwd" required={true}  />
                                                    <div className="invalid-feedback">Entre votre mot de passe</div>
                                            </div>

                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" type="submit">Connexion</button>
                                            </div>
                                            <div className="col-12">
                                                <p className="small mb-0">Pas de compte ? <a
                                                    href="https://www.youthfp.cm/register">S'inscrire ici</a></p>
                                            </div>
                                        </form>

                                    </div>
                                </div>

                                <div className="credits">
                                    © Copyright <a>AfriYAN Cameroun</a>. All Rights Reserved by
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
            className="bi bi-arrow-up-short"></i></a>
    </>;

}