import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import logo from '../img/logo.png';
import profile from '../img/profile-img.jpg';
import {useNavigate} from "react-router-dom";
import Background from "../background/Background";

export default function Header() {

    const {user} = useSelector(state => state.authUser);
    const nav = useNavigate();

    useEffect(
        ()=>{
            if (user == null) {
                nav("/login");
            }
        },[]
    );
    return <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
            <a className="logo d-flex align-items-center">
                <img src={logo} alt="" />
            </a>
            <i className="bi bi-list toggle-sidebar-btn" onClick={()=>{
                if(!document.getElementById('body').classList.contains("toggle-sidebar"))
                    document.getElementById('body').classList.add("toggle-sidebar")
                else
                    document.getElementById('body').classList.remove("toggle-sidebar")
            }}></i>
        </div>
        <nav className="header-nav ms-auto">
            <ul className="d-flex align-items-center">
                <li className="nav-item dropdown">
                    <a className="nav-link nav-icon">
                        <i className="bi bi-bell"></i>
                    </a>
                </li>
                <li className="nav-item dropdown pe-3">
                    <a className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                        <img src={user.photo==null ? profile : user.photo} alt="Profile" className="rounded-circle" />
                            <span className="d-none d-md-block dropdown-toggle ps-2">{(user===null) ? "" : user.nom}</span>
                    </a>

                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li className="dropdown-header">
                            <h6>{(user===null) ? "" : user.nom.toUpperCase()}</h6>
                            <span>{(user===null) ? "" : user.prenom}</span>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>

                        <li>
                            <a className="dropdown-item d-flex align-items-center" href="/home/profile">
                                <i className="bi bi-person"></i>
                                <span>Mon Profil</span>
                            </a>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <a className="dropdown-item d-flex align-items-center" href="/home/contact">
                                <i className="bi bi-person"></i>
                                <span>Nous Contacter</span>
                            </a>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <a className="dropdown-item d-flex align-items-center" href="/">
                                <i className="bi bi-box-arrow-right"></i>
                                <span>Deconnexion</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </header>;



}