import React from "react";
import {useSelector} from "react-redux";

export default function SiderBar(){
    const {user, droits} = useSelector(state => state.authUser);
    const styleSheet={
        color: '#4154f1',
        background: '#f6f9ff'
    };


    return <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-heading">Mon Espace</li>
            {
                (droits.includes("USER")) && <li className="nav-item">
                    <a className="nav-link collapsed" style={window.location.href.includes('/home/meeting') ? styleSheet : null} href="/home/meeting">
                        <i className="bi bi-journal-text"></i><span>Mes Rendez-vous</span>
                    </a>
                </li>
            }

            {
                (droits.includes("USER")) && <li className="nav-item">
                    <a className="nav-link collapsed" style={window.location.href.includes('/home/cp') ? styleSheet : null} href="/home/cp">
                        <i className="bi bi-layout-text-window-reverse"></i><span>Les USRAJ</span>
                    </a>
                </li>
            }


            {
                (droits.includes("USER")) && <li className="nav-item">
                    <a className="nav-link collapsed" style={window.location.href.includes('/home/carte') ? styleSheet : null} href="/home/carte">
                        <i className="bi bi-layout-text-window-reverse"></i><span>Localiser les USRAJ</span>
                    </a>
                </li>
            }

            {
                (droits.includes("USER")) && <li className="nav-item">
                    <a className="nav-link collapsed" style={window.location.href.includes('/home/work') ? {
                        color: '#ffffff',
                        background: '#686872'
                    } : null} data-bs-target="#components-nav" data-bs-toggle="collapse"
                       aria-expanded="false">
                        <i className="bi bi-menu-button-wide"></i><span>Articles</span><i
                        className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/SANTE_SEXUELLE') ? styleSheet : null} >
                            <a href="/home/work/SANTE_SEXUELLE">
                                <i className="bi bi-circle"></i><span>Santé Sexuelle et Reproductive</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/GROSSESSE_PRECOSE') ? styleSheet : null}>
                            <a href="/home/work/GROSSESSE_PRECOSE">
                                <i className="bi bi-circle"></i><span>Prévention des Grossésses Précoces</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/HYGENE_MENSTRUELLE') ? styleSheet : null}>
                            <a href="/home/work/HYGENE_MENSTRUELLE">
                                <i className="bi bi-circle"></i><span>Hygiéne Menstruelle</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/VIH_IST') ? styleSheet : null}>
                            <a href="/home/work/VIH_IST">
                                <i className="bi bi-circle"></i><span>IST & VIH/SIDA</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/DROGUE_STUPEFIANT') ? styleSheet : null}>
                            <a href="/home/work/DROGUE_STUPEFIANT">
                                <i className="bi bi-circle"></i><span>Drogues & Stupéfiants</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/VVG') ? styleSheet : null}>
                            <a href="/home/work/VVG">
                                <i className="bi bi-circle"></i><span>Violences basées sur le genre</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/IVG') ? styleSheet : null}>
                            <a href="/home/work/IVG">
                                <i className="bi bi-circle"></i><span>Interruption Volontaire de grossésses</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/PLANNING_FAMILLIAL') ? styleSheet : null}>
                            <a href="/home/work/PLANNING_FAMILLIAL">
                                <i className="bi bi-circle"></i><span>Planning Familiale | Contraception</span>
                            </a>
                        </li>
                        <li className="nav-link collapsed" style={window.location.href.includes('/home/work/YOUNGLEAD') ? styleSheet : null}>
                            <a href="/home/work/YOUNGLEAD">
                                <i className="bi bi-circle"></i><span>LeaderShip Jeune</span>
                            </a>
                        </li>
                    </ul>
                </li>
            }

            {
                (droits.includes("GESTIONNAIRECENTRE")) && <li className="nav-item">
                    <a className="nav-link collapsed" style={window.location.href.includes('/home/mycp') ? styleSheet : null} href="/home/mycp">
                        <i className="bi bi-layout-text-window-reverse"></i><span>Mes USRAJ</span>
                    </a>
                </li>
            }
            {
                (droits.includes("USER")) && <li className="nav-item">
                    <a className="nav-link collapsed" href="https://e-learning.youthfp.cm/" target="_blank">
                        <i className="bi bi-layout-text-window-reverse"></i><span>e-learning</span>
                    </a>
                </li>
            }


        </ul>
    </aside>;
}