import React, {useEffect, useState} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../toast/CustomToast";
import {loginUserClient, setImage} from "../../configurations/features/authSliceUser";

export default function Profile(){
    const [photo, setPhoto]=useState("/assets/img/profile-img.jpg");
    const [userData, setUserData]=useState(null);
    const dispatch = useDispatch();
    useTitle("Profile");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }
    const saveFile = (fichier)=>{
        const formData = new FormData();
        formData.append('file', fichier);
        formData.append('id', JSON.stringify(user.id));
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        axiosInstance(accessToken).put(
            "user",
            formData,config
        ).then(
            (result)=> {
                setPhoto(result.data);
                const data = JSON.parse(sessionStorage.getItem('userU'));
                data.photo=result.data;
                sessionStorage.setItem('userU',JSON.stringify(data));
                document.getElementById('preloader').classList.add('preloader-deactivate');
                toastClick('Mise a jour Ok',"success");
                setInterval(
                    ()=>{
                        window.location.reload();
                    },2000
                )
            }).catch((error)=> {
            document.getElementById('preloader').classList.add('preloader-deactivate');
            toastClick("Erreur: contacter l'administrateur du site", "error");
        });
    };
    const getFiles = (event)=>{
        let preloader=document.getElementById('preloader');
        preloader.classList.remove('preloader-deactivate');
        if(event.target.files.length>0) {
            saveFile(event.target.files[0]);
        }else{
            preloader.classList.add('preloader-deactivate');

        }
    }
    useEffect(
        ()=>{
            setPhoto("/assets/img/profile-img.jpg");
            if(user===null)
                checkIfUserIsConnect();
            else {
                setUserData({
                    nom:user.nom,
                    prenom:user.prenom
                })
                if(user.photo == null){
                    setPhoto("/assets/img/profile-img.jpg");
                }else {
                    setPhoto(user.photo)
                }
            }
        },[]
    );
    const [dataToSet, setDataToSet] = useState(
        {
            name: '',
            surname: ''
        }
    );
    const [active, setActive] = useState(
        {
            name: false,
            surname: false
        }
    );

    const setSurName = (event)=>{
        let value = event.target.value.toString().trim();
        if (value.length < 3) {
            document.getElementById('surname').style.border= '1px solid #f20c0c';
            active.surname=false;
        } else {
            dataToSet.surname = value;
            setDataToSet(dataToSet);
            document.getElementById('surname').style.border= '1px solid green';
            active.surname=true;
        }
    };
    const setName = (event)=>{
        let value = event.target.value.toString().trim();
        if (value.length < 3) {
            document.getElementById('name').style.border= '1px solid #f20c0c';
            active.name=false;
        } else {
            dataToSet.name = value;
            setDataToSet(dataToSet);
            document.getElementById('name').style.border= '1px solid green';
            active.name=true;
        }
    };

    const submitRegisterForm = (event) => {
        event.preventDefault();

        if (Object.values(active).every(value => value) & user.nom != dataToSet.name & user.prenom != dataToSet.surname) {
            document.getElementById('preloader').classList.remove("preloader-deactivate");
            const data = JSON.parse(sessionStorage.getItem('userU'));
            data.nom=dataToSet.name;
            data.prenom=dataToSet.surname;

            axiosInstance(accessToken).put(
                "user/"+user.id,
                data
            ).then(
                (result)=> {
                    sessionStorage.setItem('userU',JSON.stringify(data));
                    toastClick('Mise a jour Ok',"success");
                    setInterval(
                        ()=>{
                            document.getElementById('preloader').classList.add('preloader-deactivate');
                            window.location.reload();
                        },2000
                    )
                }).catch((error)=> {
                document.getElementById('preloader').classList.add('preloader-deactivate');
                toastClick("Erreur: contacter l'administrateur du site", "error");
            });

        } else {
            toastClick("Champs vide","info");
        }
    };


    return <main id="main" className="main">

        <div className="pagetitle">
            <h1>Profile</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Profil</li>
                </ol>
            </nav>
        </div>
        <section className="section profile">
            <div className="row">
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                            <img src={photo} alt="Profile" className="rounded-circle" />
                            <label htmlFor="file-upload" className="btn btn-primary btn-sm">
                               <i className="bi bi-upload"></i> Choisir une image
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/png"
                                onChange={getFiles}
                                style={{ display: 'none' }} // cache le champ d'entrée de fichier
                            />
                           <h2>{(user.nom===null) ? "" : user.nom.toUpperCase()}</h2>
                            <h3>{(user.prenom===null) ? "" : user.prenom.toUpperCase()}</h3>
                            <div id="status"
                                     style={
                                    {
                                        width: '25px',
                                        height: '25px',
                                        borderRadius: '50%',
                                        backgroundColor: user.status==="ACTIVE" ? 'rgba(19, 128, 0, 0.652)' : 'red'

                                    }
                                     }></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body pt-3">
                            <ul className="nav nav-tabs nav-tabs-bordered">
                                <li className="nav-item">
                                    <button className="nav-link" data-bs-toggle="tab" id="profile-edit"
                                            data-bs-target="#profile-edit">Mise a jour des informations
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                <div className="tab-pane fade show active profile-edit pt-3" id="profile-edit-view">
                                    <form onSubmit={submitRegisterForm}>
                                       <div className="row mb-3">
                                            <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">Nom</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="name" type="text" className="form-control" id="name" onChange={setName} />
                                            </div>
                                        </div>
                                       <div className="row mb-3">
                                            <label htmlFor="surname" className="col-md-4 col-lg-3 col-form-label">Prenom</label>
                                            <div className="col-md-8 col-lg-9">
                                                <input name="surname" type="text" className="form-control" id="surname" onChange={setSurName} />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary">Enregistrer les
                                                modifications
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}