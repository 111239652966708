import React, {useEffect} from "react";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import 'leaflet-routing-machine';
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toast} from "react-toastify";
import {useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";

export default function CarteLeaflet() {
    useTitle("Carte");
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }

    let greenIcon = L.icon({
        iconUrl: "/assets/img/location.png",
        iconSize: [38, 95], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    let userIcon = L.icon({
        iconUrl: "/assets/img/userlocation.png",
        iconSize: [38, 95], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    const fetchData = ()=>{
        let preloader=document.getElementById('preloader');
        preloader.classList.remove('preloader-deactivate');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                let listOfCP = new Array();
                axiosInstance().get('api/centrepartenaire/proches/'+latitude+'/'+longitude)
                    .then(function (response) {
                        let data = response.data;
                        let popupUser = '';
                        popupUser += '<h3><strong>Vous etres ici: </strong>';
                        listOfCP.push(L.marker([latitude, longitude], { icon: greenIcon }).bindPopup(popupUser));

                        if ((data == null) || (data.length == 0)) {
                        } else {
                            for (let index = 0; index < data.length; index++) {
                                let popup = '<img width="100%" height="100%" src='+data[index].photo+' />';
                                popup += '<h3><strong>Nom: </strong>' + data[index].nom + '</h3>';
                                popup += '<p><strong>Localisation: </strong>' + data[index].location + '</p>';
                                popup += '<p><strong>Telephone: </strong>' + data[index].telephone + '</p>';
                                popup += '<p><strong>Services : </strong>' + data[index].serviceOfferts.length + '</p>';
                                if(data[index].serviceOfferts.length>0)
                                    popup += '<p><a href="cp/'+data[index].id+'">En savoir plus.</a></p>';
                                let mq = L.marker([data[index].latittude, data[index].longitude], { icon: greenIcon }).bindPopup(popup);
                                listOfCP.push(mq);
                            }
                        }
                        let cp = L.layerGroup(listOfCP);
                        let openstreetmap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                            maxZoom: 19,
                            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        });

                        let googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                            maxZoom: 20,
                            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                        });

                        let googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                            maxZoom: 20,
                            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                        });
                        let map = L.map('map', {
                            layers: [openstreetmap, cp]
                        });


                        let baseMaps = {
                            "Google Map Hybrid": googleHybrid,
                            "Google Streets": googleStreets,
                            "Open Street Map": openstreetmap

                        };

                        let overlayMaps = {
                            "USRAJ": cp
                        };
                        let layerControl = L.control.layers(baseMaps, overlayMaps).addTo(map);
                        map.locate({ setView: true, maxZoom: 16 });
                        function onLocationFound(e) {
                            var radius = e.accuracy;
                            L.circle(e.latlng, radius).addTo(map);
                        }

                        map.on('locationfound', onLocationFound);
                        function onLocationError(e) {
                            toast("Impossible d'avoir votre localisation", 'info');
                        }
                        map.on('locationerror', onLocationError);

                        for (let index = 0; index < data.length; index++) {
                            L.Routing.control({
                                waypoints: [
                                    L.latLng(latitude, longitude),  // Douala
                                    L.latLng(data[index].latittude, data[index].longitude)  // Yaoundé
                                ],
                                routeWhileDragging: false,  // Désactiver le glisser-déposer de l'itinéraire
                                lineOptions: {
                                    styles: [
                                        {
                                            color: index==0 ? "green":"red",
                                            opacity: 0.7,
                                            weight: 4
                                        }
                                        ] // Style de la ligne d'itinéraire
                                },
                                router: L.Routing.osrmv1({
                                    serviceUrl: "https://router.project-osrm.org/route/v1"
                                }),
                                createMarker: function(i, waypoint, n) {
                                    // Appliquer l'icône de départ ou d'arrivée selon l'index du point
                                    const markerOptions = {
                                        icon: i === 0 ? greenIcon : greenIcon  // Utiliser startIcon pour le point de départ et endIcon pour l'arrivée
                                    };
                                    // return L.marker(waypoint.latLng, markerOptions);
                                    return null;
                                },/**/
                                show: false
                            }).addTo(map);
                            map.on('load', () => {
                                const routingContainer = document.querySelector('.leaflet-routing-container');
                                if (routingContainer) {
                                    routingContainer.remove(); // Supprimer la description de l'itinéraire
                                }
                            });
                        }


                    })
                    .catch(function (error) {
                        let cp = L.layerGroup([]);
                        let openstreetmap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                            maxZoom: 19,
                            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        });

                        let googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                            maxZoom: 20,
                            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                        });

                        let googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                            maxZoom: 20,
                            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                        });
                        let map = L.map('map', {
                            layers: [openstreetmap, cp]
                        });


                        let baseMaps = {
                            "Google Map Hybrid": googleHybrid,
                            "Google Streets": googleStreets,
                            "Open Street Map": openstreetmap

                        };

                        let overlayMaps = {
                            "USRAJ": cp
                        };
                        let layerControl = L.control.layers(baseMaps, overlayMaps).addTo(map);
                        map.locate({ setView: true, maxZoom: 16 });
                        function onLocationFound(e) {
                            let radius = e.accuracy;
                            L.circle(e.latlng, radius).addTo(map);
                        }

                        map.on('locationfound', onLocationFound);
                        function onLocationError(e) {
                            toast("Impossible d'avoir votre localisation", 'info');
                        }
                        map.on('locationerror', onLocationError);
                    });
            }, function(error) {
                toast("Erreur lors de la récupération de la position : ");
                preloader.classList.add('preloader-deactivate');
            });
        } else {
            toast("La géolocalisation n'est pas supportée par ce navigateur.");
            preloader.classList.add('preloader-deactivate');
        }
        preloader.classList.add('preloader-deactivate');
    }

    useEffect(
        ()=>{
            document.title='Carte';
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchData();
        },[]
    );

    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Carte</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">carte</li>
                </ol>
            </nav>
        </div>
        <section className="section profile">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body pt-3 align-items-center flex-column" id="map" style={
                            {
                                height: '600px'
                            }
                        }>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    </main>;

}