import React, {useEffect, useState} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {toastClick} from "../toast/CustomToast";
import {useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {NavLink} from "react-router-dom";

export default function Contact(){
    const [links, setLinks] = useState([]);
    const [tel, setTel] = useState(null);
    useTitle("Contact");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);

    function checkIfUserIsConnect() {
        window.location='/';
    }
    const contactUsSubmit = (event)=>{
        event.preventDefault();
        toastClick("Non disponible","info");
    }
    const fetchContact = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        axiosInstance()
            .get("api/parametres/get/links")
            .then((response)=>{
                let data = response.data;
                let fetchedLinks = [];
                let fetchedLinksCall = [];
                let fetchedLinksSMS = [];

                if (data != null) {
                    if (data.call != null) {
                        setTel(data.call.valeur);
                    }
                    if (data.facebook != null) {
                        fetchedLinks.push(
                            <li key="facebook" title={data.facebook.valeur}>
                                <NavLink to={data.facebook.valeur}><i className="fa fa-facebook-official"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.sms != null) {
                        fetchedLinks.push(
                            <li key="sms" title={data.sms.valeur}>
                                <NavLink to={`sms:${data.sms.valeur}`}><i className="fa fa-commenting"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.tweeter != null) {
                        fetchedLinks.push(
                            <li key="tweeter" title={data.tweeter.valeur}>
                                <NavLink to={data.tweeter.valeur}><i className="fa fa-twitter"></i></NavLink>
                            </li>
                        );
                    }
                    if (data.whatsapp != null) {
                        fetchedLinks.push(
                            <li key="whatsapp" title={data.whatsapp.valeur}>
                                <NavLink to={`https://api.whatsapp.com/send?phone=${data.whatsapp.valeur}`}><i className="fa fa-whatsapp"></i></NavLink>
                            </li>
                        );
                    }
                }


                preloader.classList.add("preloader-deactivate");
                setLinks(fetchedLinks);
            }).catch((error)=>{
            preloader.classList.remove("preloader-deactivate");
        })
    }

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else {
                fetchContact();
            }
        },[]
    )
    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Contact</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Contact</li>
                </ol>
            </nav>
        </div>
        <section className="section contact">
            <div className="row gy-4">
                <div className="col-xl-6">
                    <div className="row">
                        {
                            tel != null &&
                            <div className="col-lg-6">
                                <div className="info-box card">
                                    <i className="bi bi-telephone"></i>
                                    <h3>Call Us</h3>
                                    <p><NavLink to={`tel:${tel}`}><i className="fa fa-phone"></i>{tel}</NavLink></p>
                                </div>
                            </div>
                        }
                        <div className="col-lg-6">
                            <div className="info-box card">
                                <i className="bi bi-envelope"></i>
                                <h3>Email</h3>
                                <p>youthfp@youthfp.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card p-4">
                        <form className="php-email-form" onSubmit={contactUsSubmit}>
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <input type="text" name="name" className="form-control" placeholder="Votre Nom" required />
                                </div>
                                <div className="col-md-6 ">
                                    <input type="email" className="form-control" name="email" placeholder="Votre Mail" required />
                                </div>
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="subject" placeholder="Suject" required />
                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Cotre message</div>
                                    <button type="submit">Envois</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}