import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode"; // Correction de l'importation
import axiosInstance from "../axiosInstance/AxiosInstance";
import { toastClick } from "../../components/toast/CustomToast";

function isValidJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const authSliceUser = createSlice({
    name: 'authUser',
    initialState: {
        accessToken: sessionStorage.getItem('accessTokenU'),
        refreshToken: sessionStorage.getItem('refreshTokenU'),
        tokensubject: sessionStorage.getItem('subU'),
        droits: isValidJSON(sessionStorage.getItem('rolesU')) ? JSON.parse(sessionStorage.getItem('rolesU')) : [],
        user: isValidJSON(sessionStorage.getItem('userU')) ? JSON.parse(sessionStorage.getItem('userU')) : null,
        isAuth: sessionStorage.getItem('isAuthU'),
    },

    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = sessionStorage.getItem('accessTokenU')
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = sessionStorage.getItem('refreshTokenU')
        },
        setSubject: (state, action) => {
            state.tokensubject = sessionStorage.getItem('subU')
        },
        setDroits: (state, action) => {
            state.droits = isValidJSON(sessionStorage.getItem('rolesU')) ? JSON.parse(sessionStorage.getItem('rolesU')) : [];
        },
        setUser: (state, action) => {
            state.user = isValidJSON(sessionStorage.getItem('userU')) ? JSON.parse(sessionStorage.getItem('userU')) : null;
            state.isAuth = state.user ? state.user.isFirstConnexion : false;
        },
        logOut: (state, action) => {
            sessionStorage.clear()
            state.droits = [];
            state.tokensubject = null;
            state.accessToken = null;
            state.user = null;
        },
        getToken: (state) => {
            return state.accessToken;
        }
    }
})


export const { setRefreshToken, setAccessToken, setDroits, logOut,setSubject, setUser, getToken } = authSliceUser.actions
export const loginUserClient = (identifier, password) => async dispatch => {
    await axiosInstance().post("api/auth/login", {
        login: identifier,
        password: password
    }).then(response => {
        let accessToken = response.data.accessToken.replace(/^Bearer\s/, '');
        const refreshToken = response.data.refreshToken;
        let decodedToken;
        try {
            decodedToken = jwt_decode(accessToken);
            // Vérifier si le token est expiré
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodedToken.exp < currentTime) {
                toastClick("Token expiré, veuillez vous reconnecter", "error");
                document.getElementById('preloader').classList.add("preloader-deactivate");
                return;
            }
        } catch (error) {
            toastClick("Erreur lors du décodage du token", "error");
            document.getElementById('preloader').classList.add("preloader-deactivate");
            return;
        }

        sessionStorage.setItem('accessTokenU',accessToken);
        sessionStorage.setItem('refreshTokenU',refreshToken);
        sessionStorage.setItem('rolesU',JSON.stringify(decodedToken.roles));
        sessionStorage.setItem('subU',decodedToken.sub);
        sessionStorage.setItem('userU',JSON.stringify(response.data.user));
        sessionStorage.setItem('isAuthU',response.data.user.isFirstConnexion);
        if(response.data.user.isFirstConnexion)
            sessionStorage.setItem('passwordU',password)
        dispatch(setAccessToken(sessionStorage.getItem('accessTokenU')));
        dispatch(setRefreshToken(sessionStorage.getItem('refreshTokenU')));
        dispatch(setDroits(sessionStorage.getItem('rolesU')));
        dispatch(setSubject(sessionStorage.getItem('subU')));
        dispatch(setUser(response.data.user));
        toastClick("Welcome", "success");
        window.location.href="/home/meeting";
    }).catch(error => {
        toastClick("Login / Ou Mot de passe Invalide", "error");
        document.getElementById('preloader').classList.add("preloader-deactivate");
    });
};

export const logOutUser = () => async dispatch => {
    dispatch(logOut(""));
};
export const setImage = (data) => dispatch => {
    sessionStorage.setItem('userU',JSON.stringify(data));
    dispatch(setUser(data));
};
export const setInfos = (nom, prenom, user) => async dispatch => {
    user.nom=nom;
    user.prenom=prenom;
    sessionStorage.setItem('userU',JSON.stringify(user));
};

export default authSliceUser.reducer;
