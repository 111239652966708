import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Définition des styles
const styles = StyleSheet.create({
    wrapperInvoice: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        backgroundColor: '#ffffff',
    },
    invoice: {
        padding: 20,
        border: '1px solid #dcdcdc',
        width: '100%',
        maxWidth: '110vh',
        boxSizing: 'border-box',
    },
    invoiceInformation: {
        textAlign: 'right',
        color: 'gray',
        fontSize: 12,
        marginBottom: 10,
    },
    boldText: {
        color: '#0F172A',
        fontWeight: 'bold',
    },
    invoiceLogo: {
        maxWidth: 100,
        marginBottom: 20,
    },
    clientInfo: {
        fontSize: 12,
        color: 'gray',
        width: '50%',
    },
    invoiceHead: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    table: {
        display: 'table',
        width: 'auto',
        marginTop: 20,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        width: '50%',
        borderStyle: 'solid',
        borderColor: '#dcdcdc',
        borderWidth: 1,
        padding: 8,
    },
    tableCellHeader: {
        fontWeight: 'bold',
        backgroundColor: '#eeeeee',
    },
    tableCell: {
        fontSize: 12,
        color: 'gray',
    },
    subtotalTable: {
        marginTop: 20,
        alignSelf: 'flex-end',
    },
    totalAmount: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'right',
        color: '#0F172A',
        marginTop: 10,
    },
    footer: {
        fontSize: 10,
        color: 'gray',
        textAlign: 'center',
        marginTop: 20,
    },
});

const InvoicePDF = () => (
    <Document>
        <Page size="A4" style={styles.wrapperInvoice}>
            <View style={styles.invoice}>
                {/* Information sur la facture */}
                <View style={styles.invoiceInformation}>
                    <Text style={styles.boldText}>Invoice #</Text>: 12345
                    <Text style={styles.boldText}>Created Date</Text>: May, 07 2022
                    <Text style={styles.boldText}>Due Date</Text>: May, 09 2022
                </View>

                {/* Logo de la facture */}
                <Image style={styles.invoiceLogo} src="/assets/img/logounfpa.png" />

                {/* En-tête de la facture */}
                {/*<View style={styles.invoiceHead}>
                    <View style={styles.clientInfo}>
                        <Text>Tampsh, Inc.</Text>
                        <Text>NPWP: 12.345.678.910.111213.1415</Text>
                        <Text>Bondowoso, Jawa timur</Text>
                        <Text>Jln. Rengganis 05, Bondowoso</Text>
                    </View>
                    <View style={styles.clientInfo}>
                        <Text>-</Text>
                        <Text>Mohammad Sahrullah</Text>
                        <Text>Bondowoso, Jawa timur</Text>
                        <Text>Jln. Duko Kembang, Bondowoso</Text>
                    </View>
                </View>*/}

                {/* Corps de la facture - Tableau */}
               {/* <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCol, styles.tableCellHeader]}>Item Description</Text>
                        <Text style={[styles.tableCol, styles.tableCellHeader]}>Amount</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>Template Invoice</Text>
                        <Text style={styles.tableCol}>Rp.75.000</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>tax</Text>
                        <Text style={styles.tableCol}>Rp.5.000</Text>
                    </View>
                </View>*/}

                {/* Tableau des sous-totaux */}
{/*                <View style={styles.subtotalTable}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>Subtotal</Text>
                        <Text style={styles.tableCol}>Rp.80.000</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>PPN 10%</Text>
                        <Text style={styles.tableCol}>Rp.5.000</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableCol}>Credit</Text>
                        <Text style={styles.tableCol}>Rp.0</Text>
                    </View>
                </View>*/}

                {/* Montant total */}
                <Text style={styles.totalAmount}>Total: Rp.80.000</Text>

                {/* Pied de page */}
                <Text style={styles.footer}>Thank you, happy shopping again</Text>
            </View>
        </Page>
    </Document>
);

export default InvoicePDF;
