import React, {useEffect, useState} from "react";
import {isUserConnect, useTitle} from "../../utils/Utils";
import {useSelector} from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {useParams} from "react-router-dom";
import image from "../img/Health-Insurance.jpg";
import {toast} from "react-toastify";
import L from "leaflet";

export default function CentrePartenairesId(){
    const {id} = useParams();
    const [cp, setCP] = useState([]);
    const [userLocalusation, setUserLocalusation] = useState(null);
    useTitle("USRAJ");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);
    function checkIfUserIsConnect() {
        window.location='/';
    }
    const calculateDistance = (userLat, userLon, cpLat, cpLon)=>{
        const R = 6371; // Rayon de la Terre en kilomètres
        const latDistance = toRadians(cpLat - userLat);
        const lonDistance = toRadians(cpLon - userLon);

        const a = Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
            Math.cos(toRadians(userLat)) * Math.cos(toRadians(cpLat)) *
            Math.sin(lonDistance / 2) * Math.sin(lonDistance / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    }

    const toRadians = (degrees)=>{
        return degrees * (Math.PI / 180);
    }


    const fetchCP = () => {
        let preloader = document.getElementById('preloader');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setUserLocalusation({
                    latitude: latitude,
                    longitude:longitude
                });
                axiosInstance().get('api/centrepartenaire/getById/'+id)
                    .then(function (response) {
                        let data = [];
                        data.push(response.data);
                        if((data == null)||(data.length===0)){
                            setCP([])
                        }else{
                            setCP(data);
                            preloader.classList.add("preloader-deactivate");
                        }
                    })
                    .catch(function (error) {
                        setCP([]);
                        preloader.classList.add("preloader-deactivate");
                    });
            }, function(error) {
                toast("Erreur lors de la récupération de la position.");
            });
        } else {
            toast("La géolocalisation n'est pas supportée par ce navigateur.");
        }
        preloader.classList.remove("preloader-deactivate");
    };

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchCP();
        },[]
    )

    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>USRAJ</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">USRAJ</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="card-body pb-0">
                                    <h5 className="card-title">USRAJ : {cp.length!=0 && cp[0].nom}</h5>
                                    <div className="row">
                                        <div className="col-12">
                                            {
                                                cp.map((value) => {
                                                    const distance = calculateDistance(
                                                        userLocalusation.latitude,userLocalusation.longitude,value.latittude,value.longitude);
                                                    return <>
                                                        <div key={value.id} style={
                                                            {
                                                                display: "inline-flex",
                                                                width:'100%',
                                                                height:"230px"
                                                            }
                                                        }>
                                                            <div key={value.id+'div.img'}  style={{width:'230px', height:"230px"}}>
                                                                <img key={value.id+'img'} src={value.photo}
                                                                     style={
                                                                         {
                                                                             height: '100%',
                                                                             width: '100%'
                                                                         }
                                                                     } />
                                                            </div>
                                                            <div key={value.id+'div.info'}  style={{width:'100%', height:"230px", marginLeft: '10px'}}>
                                                                <div key={value.id+'div.info.1'} style={{display: 'inline-grid', gridTemplateColumns: '1fr 1fr 1fr', width:'100%'}}>
                                                                    <p key={value.id+'div.info.1.name'}><strong title={"Unité de Santé de Reproduction Ados Jeunes"}>USRAJ: </strong>{value.nom}</p>
                                                                    <p key={value.id+'div.info.1.distance'}><strong >Vous-USRAJ: </strong>{(Math.round(distance * 100) / 100)+' Km'}</p>
                                                                    <p key={value.id+'div.info.1.categorie'}><strong>Catégorie: </strong>{value.category}</p>
                                                                </div>
                                                                <div key={value.id+'div.info.3'} style={{display: 'inline-grid', gridTemplateColumns: '1fr 1fr', width:'100%'}}>
                                                                    <p key={value.id+'div.info.1.tel'}><strong>Numero Telephone: </strong>{value.telephone==null ? "null" : value.telephone}</p>
                                                                    <p key={value.id+'div.info.1.address'}><strong>Adresse: </strong>{value.address==null ? "null" : value.address}</p>
                                                                </div>
                                                                <div key={value.id+'div.info.4'} style={{display: 'inline-grid', gridTemplateColumns: '1fr', width:'100%'}}>
                                                                    <p key={value.id+'div.info.1.address'}><strong>Coordonnées GPS: </strong>[{value.latittude+', '+value.longitude}]</p>
                                                                </div>
                                                                <div key={value.id+'div.info.5'} style={{display: 'inline-grid', gridTemplateColumns: '1fr 1fr', width:'100%'}}>
                                                                    <p key={value.id+'div.info.1.sup'}><strong>Superficie: </strong>{value.superficie+' Km'}</p>
                                                                    <p key={value.id+'div.info.1.bat'}><strong>Nombre de Batiments: </strong>{value.nombreBat}</p>
                                                                </div>
                                                                <div key={value.id+'div.info.6'} style={{display: 'inline-grid', gridTemplateColumns: '1fr 1fr', width:'100%'}}>
                                                                    <p key={value.id+'div.info.1.lit'}><strong>Nombre de Lits: </strong>{value.nombreLit}</p>
                                                                    <p key={value.id+'div.info.1.sup'}><strong>Morgue: </strong>{value.morgue?'Oui':'Non'}</p>
                                                                </div>
                                                                {
                                                                    value.serviceOfferts.length != 0 && <>
                                                                        <div key={value.id+'div.info.2'} style={{display: 'inline-flex', gridTemplateColumns: '1fr 1fr', width:'100%', backgroundColor: '#009900'}}>
                                                                            <i className="fa fa-medkit fa-3x" style={{color:'#cc9900'}}></i>
                                                                            <h1 key={value.id+'div.info.1.service'} style={{marginLeft: '10px'}}><strong>Services</strong></h1>
                                                                        </div>
                                                                        <p></p>
                                                                        <div key={value.id+'div.info.7'} style={{display: 'inline-flex', width:'100%'}}>
                                                                            {
                                                                                value.serviceOfferts.map((service)=>{
                                                                                    return <span key={service.id} color={"cyan"} style={{marginTop:'5px'}}>
                                                                                        <h5>{value.libelle}</h5>
                                                                                    </span>

                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;

}